import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useParams} from "react-router-dom";
import {getTandemTypeDetails} from "../../api";
import { TandemTypeDetails} from "../../types";
import ListPanel from "../../components/ListPanel";

// Usage:
// <Link className="text-white" to={`/list/${typeId}`}>Types</Link>
const ListPage: React.FC = () => {
    const { typeId } = useParams<{ typeId: string }>();
    const [tandemType, setTandemType] = useState<TandemTypeDetails | null>(null);

    useEffect(() => {
        if(!typeId) return;
        getTandemTypeDetails(typeId).then((response) => {
            setTandemType(response);
        });
    }, [typeId]);

    if(!tandemType) return <div>Loading Type...</div>;
    console.log('tandemType', tandemType)
    return <ListPanel tandemTypeDetails={tandemType} />;
};

export default ListPage;


