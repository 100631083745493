import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { useState } from 'react';
import 'tailwindcss/tailwind.css';
import logo from '../logo.png';
import SettingsPage from "../pages/settings/SettingsPage";

const NavBar: React.FC = () => {
    const { loggedIn, logout } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={`bg-grey-100 p-3 flex flex-col h-screen`}>
            <button onClick={toggleNav} className="text-white mb-2">
                <img src={logo} alt="logo" style={{ width: '50px', height: '50px' }} />
            </button>
            {isOpen && (
                <>
                    <Link className="text-white" to="/">Home</Link>
                    {loggedIn && <Link className="text-white" to="/workspace">Workspace</Link>}
                    {loggedIn && <Link className="text-white" to="/detail/00000000-0000-0000-0000-000000000000">Types</Link>}
                    {loggedIn && <Link className="text-white" to="/tasks">Tasks</Link>}
                    {loggedIn &&
                        <button className="bg-red-500 rounded" onClick={logout}>Logout</button>}
                    {!loggedIn &&
                        <Link className="text-white" to="/settings">Settings</Link>}
                        
                </>
            )}
        </nav>
    );
};

export default NavBar;


