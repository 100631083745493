import React, { useEffect, useState } from 'react';
import { FieldType, TandemTypeDetails, TandemTypeField } from '../types';
import { addNewNode, searchNodes, SearchRequestDataQueryType, SortDirection } from '../api';
import startCase from 'lodash/startCase';
import { toLower } from "lodash";

interface AddFieldModalProps {
    open: boolean;
    handleClose: () => void;
    tandemTypeDetails: TandemTypeDetails;
}

const AddFieldModal: React.FC<AddFieldModalProps> = ({ open, handleClose, tandemTypeDetails }) => {
    const FIELDS_TO_IGNORE = ['id', 'created_at', 'updated_at', 'tandem_type_id'];
    const [formValues, setFormValues] = useState<Partial<Record<keyof TandemTypeField, any>>>({});
    const [tandemTypeFieldTandemTypeFields, setTandemTypeFieldTandemTypeFields] = useState<TandemTypeField[] | null>(null);

    useEffect(() => {
        searchNodes('11111111-1111-1111-1111-111111111111', {
            where: [{
                key: 'tandem_type_id',
                value: '11111111-1111-1111-1111-111111111111',
                queryType: SearchRequestDataQueryType.equals
            }],
            offset: 0,
            limit: 100,
            sort: [{
                field: 'created_at',
                direction: SortDirection.ascending
            }]
        }).then((response) => {
            setTandemTypeFieldTandemTypeFields(response as TandemTypeField[]);
        });
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async () => {
        if (!tandemTypeFieldTandemTypeFields) return;

        // validate that fieldTypes is in FieldType enum
        const validFieldTypes = Object.values(FieldType);
        if (!validFieldTypes.includes(formValues.field_type as FieldType)) {
            alert('invalid field type');
            console.error('invalid field type');
            return;
        }

        // Validate that fieldName is snake_case
        const fieldNameRegex = /^[a-z0-9_]+$/;
        if (!fieldNameRegex.test(formValues.field_name)) {
            alert('invalid field name');
            console.error('invalid field name');
            return;
        }

        let newField: Partial<TandemTypeField> = {
            tandem_type_id: tandemTypeDetails.tandemType.id,
        };

        for (const [key, value] of Object.entries(formValues) as [keyof typeof formValues, any][]) {
            const field = tandemTypeFieldTandemTypeFields.find((field) => field.field_name === key);
            if (!field) continue;

            if (field.field_type === FieldType.BOOLEAN) {
                newField[key] = (toLower(value) === 'true') as any;
                continue;
            }
            newField[key] = value;
        }

        await addNewNode('11111111-1111-1111-1111-111111111111', newField);
        handleClose();
    };

    const renderEditField = (field: TandemTypeField) => {
        if (FIELDS_TO_IGNORE.includes(field.field_name)) return null;

        if (field.field_name === 'field_type') {
            return (
                <div key={field.id} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={field.field_name}>
                        {startCase(field.field_name)}
                    </label>
                    <select
                        id={field.field_name}
                        name={field.field_name}
                        value={formValues[field.field_name] || ''}
                        onChange={handleInputChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                        {Object.values(FieldType).map((fieldType) => (
                            <option key={fieldType} value={fieldType}>
                                {startCase(fieldType)}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }

        return (
            <div key={field.id} className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={field.field_name}>
                    {startCase(field.field_name)}
                </label>
                <input
                    id={field.field_name}
                    name={field.field_name}
                    type="text"
                    value={formValues[field.field_name as keyof TandemTypeField] || ''}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
        );
    };

    if (!tandemTypeFieldTandemTypeFields) return null;

    if (!open) {
        return null
    }

    return (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                    <div className="flex justify-between items-center border-b pb-3">
                        <h2 className="text-xl font-semibold">Add New Field to Type: {startCase(tandemTypeDetails.tandemType.name)}</h2>
                        <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-4">
                        {tandemTypeFieldTandemTypeFields.map((field) => renderEditField(field))}
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                        <button
                            onClick={handleClose}
                            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Add Field
                        </button>
                    </div>
                </div>
            </div>
    );
};

export default AddFieldModal;
