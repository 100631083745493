import React, {useEffect, useState} from 'react';
import '../../App.css';
import {searchNodes, SearchRequestDataQueryType, SortDirection} from "../../api";
import IconDroplet from "../../svgs/IconDroplet";
import TasksListView from "./TasksListView";

interface TasksPageProps {
    parentId?: string
}

const TasksPage: React.FC<TasksPageProps> = ({parentId}: { parentId?: string }) => {


    return (
        <div>
            <h1>Tasks</h1>
            <TasksListView/>

        </div>
    );
};

export default TasksPage;


