import React, {useEffect, useState} from 'react';
import '../../App.css';
import {searchNodes, SearchRequestDataQueryType, SortDirection} from "../../api";
import IconDroplet from "../../svgs/IconDroplet";
import {Task} from "../../types";
import {taskTypeId} from "../../constants";

interface TasksPageProps {
    parentId?: string
}

const TasksListView: React.FC<TasksPageProps> = ({parentId}: { parentId?: string }) => {
    const [tasks, setTasks] = useState<Task[]>([]);

    useEffect(() => {
        const fetchItems = async () => {
            console.log('getting tasks')
            setTasks([]);
            let wheres = [
                {
                    key: 'parent_id',
                    value: '',
                    queryType: SearchRequestDataQueryType.is_null
                },
                {
                    key: "archived",
                    value: "false",
                    queryType: SearchRequestDataQueryType.equals
                }
            ]

            if (parentId) {
                wheres = [
                    {
                        key: 'parent_id',
                        value: parentId,
                        queryType: SearchRequestDataQueryType.equals
                    },
                    {
                        key: "archived",
                        value: "false",
                        queryType: SearchRequestDataQueryType.equals
                    }
                ]
            }


            const response = await searchNodes(taskTypeId, {
                where: wheres,
                offset: 0,
                limit: 100,
                sort: [{
                    field: 'created_at',
                    direction: SortDirection.ascending
                }]
            });
            if (response) setTasks(response as unknown as Task[]);
        };

        fetchItems();
    }, []);


    return (
        <div>
            <div className='flex gap-2  flex-row flex-wrap justify-around'>
                <div>
                    {parentId && (
                        <a href={`/tasks/add?parentId=${parentId}`} className='flex flex-row'>
                            <h1>Add</h1>
                        </a>
                    )}
                    {!parentId && (
                    <a href={`/tasks/add`} className='flex flex-row'>
                        <h1>Add</h1>
                    </a>
                )}
                </div>
                {tasks.map(task => (
                    <a href={`/tasks/${task.id}`} key={task.id}
                       className='flex flex-col justify-between border-2 p-4 rounded-3xl w-1/5 '>
                        <h2 className={'font-bold text-2xl'}>{task.title}</h2>
                        <p>{task.details}</p>
                        <p>{task.nextRun?.toString()}</p>
                        <p>{task.archived}</p>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-0.5'>
                                <h2>Status: </h2>
                                <p>{task.status}</p>
                            </div>
                            <div className='flex flex-row'>
                                <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                                <p>Time</p>
                            </div>
                        </div>
                        <div>
                            <button type="button"> View output</button>
                        </div>
                    </a>
                ))}
            </div>

        </div>
    );
};

export default TasksListView;


