import React, {useEffect, useState} from 'react';
import {
    TandemNode,
    TandemTypeDetails,
} from '../types';
import {getBlobDownloadUrl, searchNodes, SortDirection} from '../api'
import DetailRow from './DetailRow';
import {CONTAINER_COL, CONTAINER_ROW, HEADER_ROW} from "../constants/styles.constants";
import AddFieldModal from "./AddFieldModal";
import startCase from "lodash/startCase";
import AddItemModal from "./AddItemModal";
import {Link} from "react-router-dom";
import {BLOB_TYPE_ID} from "../typeIdConstants";

const ListPanel = ({tandemTypeDetails}: { tandemTypeDetails: TandemTypeDetails }) => {
    const [items, setItems] = useState<TandemNode[]>([]);
    const [page, setPage] = useState(0);
    const [openAddFieldModal, setOpenAddFieldModal] = useState(false); // State to handle the opening and closing of the modal
    const [openAddItemModal, setOpenAddItemModal] = useState(false); // State to handle the opening and closing of the modal

    const handleOpenItem = () => {
        setOpenAddItemModal(true);
    }

    const handleOpenField = () => {
        setOpenAddFieldModal(true);
    }

    const handleCloseField = () => {
        setOpenAddFieldModal(false);
    };

    const handleCloseItem = () => {
        setOpenAddItemModal(false);
    }

    useEffect(() => {
        const fetchItems = async () => {
            console.log('fetching items', tandemTypeDetails.tandemType.name)
            setItems([]);
            const response = await searchNodes(tandemTypeDetails.tandemType.id, {
                where: [],
                offset: 100 * page,
                limit: 100,
                sort: [{
                    field: 'created_at',
                    direction: SortDirection.ascending
                }]
            });
            if (response) setItems(response);
        };

        fetchItems();
    }, [tandemTypeDetails.tandemType.id, page]);

    console.log('tandemTypeDetails', tandemTypeDetails.tandemType.name)

    return (
        <div className={"flex flex-col"}>
            <div className={HEADER_ROW}>
                <h2 className={"font-semibold"}>{startCase(tandemTypeDetails.tandemType.name)} Items</h2>
                <button className="rounded bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleOpenField}>Add Field To Type</button>
                <button className="rounded bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleOpenItem}>Add Item</button>
            </div>
            <h2 className={"font-semibold"}>Type Fields</h2>
            <div className={CONTAINER_COL}>
            {tandemTypeDetails.tandemTypeFields.map((field) => {
                return <div className={CONTAINER_ROW}>
                    <div className={"flex flex-row"}>
                        <h3 className={"font-semibold"}>{field.field_name}</h3>
                    </div>
                    <div className={"flex flex-row"}>
                        <h4 className={"font-semibold"}>{field.field_type}</h4>
                    </div>
                </div>
            })}
            </div>
            <h2 className={"font-semibold"}>Items</h2>
            <div className={CONTAINER_COL}>
                {items.map((item) => {
                    return <Link to={`/detail/${tandemTypeDetails.tandemType.id}/${item.id}/`}>
                        <DetailRow key={item.id} tandemTypeDetails={tandemTypeDetails} data={item}/>
                        {tandemTypeDetails.tandemType.id === BLOB_TYPE_ID && ['jpg', 'jpeg','png'].includes(item['extension']) && <>
                            <img src={getBlobDownloadUrl(item.id)} alt={"image"} className={"w-1/4"}/>
                        </>}
                    </Link>
                })}
            </div>
            <AddFieldModal open={openAddFieldModal} handleClose={handleCloseField}
                           tandemTypeDetails={tandemTypeDetails}/>
            <AddItemModal open={openAddItemModal} handleClose={handleCloseItem}
                          tandemTypeDetails={tandemTypeDetails}/>
            <div className={CONTAINER_ROW}>
                <button onClick={() => setPage(page - 1)} disabled={page === 0}>Previous</button>
                <span>{page + 1}</span>
                <button onClick={() => setPage(page + 1)}>Next</button>
            </div>
        </div>
    );
};

export default ListPanel;

