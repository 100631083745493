import React from "react";
import {WorkspaceObjectDef} from "../pages/workspace/workspaceTypes";
import startCase from "lodash/startCase";
import {hashStringToColor} from "../pages/workspace/Visualizer";


const WorkspaceDetail: React.FC<{
    workspaceObjectDef: WorkspaceObjectDef,
    onClick?: (workspaceObjectDef: WorkspaceObjectDef) => void
    onAddConnection?: (workspaceObjectDef: WorkspaceObjectDef) => void
}> = ({
          workspaceObjectDef, onClick, onAddConnection
      }) => {

    const handleDeleteConnection = (connectionIndex: number) => {
        // Implement functionality to delete a connection
        console.log('Delete connection at index:', connectionIndex);
    };


    return (
        <div className="p-4 shadow-lg rounded-lg" onClick={() => onClick?.(workspaceObjectDef)}>
            <h1 className="text-2xl font-bold flex items-center">
                <span className="h-8 w-8 rounded-full inline-block mr-2" style={{
                    backgroundColor: hashStringToColor(workspaceObjectDef.workspaceObjectId)
                }}></span>
                {startCase(workspaceObjectDef.type.tandemType.name)}:
            </h1>
            {workspaceObjectDef.selected && (
                <div>
                    <p className="mt-2 mb-4">{workspaceObjectDef.node.code}</p>
                    <h2 className="text-xl font-semibold">Connections</h2>
                    <div className="mt-2">
                        {workspaceObjectDef.connections.map((connection, index) => (
                            <div key={index}
                                 className="flex items-center justify-between p-rounded-lg mb-2 shadow">
                                <span>{connection.type.tandemType.name} - State Key: {connection.node.state_key} - Function Key: {connection.node.function_key}</span>
                                <button
                                    className="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleDeleteConnection(index)}
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                    <button
                        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => onAddConnection?.(workspaceObjectDef)}
                    >
                        Add Connection
                    </button>
                </div>
            )}
        </div>
    );
};

export default WorkspaceDetail;
