import {FieldType, TandemTypeDetails, TandemTypeField} from "../types";
import startCase from "lodash/startCase";
import React from "react";
import 'tailwindcss/tailwind.css';
import ListPanel from "./ListPanel";
import {sortFieldsByTitleness} from "../utils";
import {Link} from "react-router-dom";
import {WORKSPACE_TYPE_ID} from "../typeIdConstants";

export type DataDetailProps = {
    data: any;
    type: TandemTypeDetails;
}
const DataDetail = ({data, type}: DataDetailProps) => {

    const isWorkspace = type.tandemType.id === WORKSPACE_TYPE_ID;

    const getValue = (field: TandemTypeField, value: any) => {
        if (field.field_type === FieldType.BOOLEAN) {
            return value ? '✅ True' : '❌ False';
        }
        console.log('field', field, 'value', value)
        if (field.field_type === FieldType.DATETIME) {
            return new Date(value).toLocaleString();
        }
        return value;
    }

    const renderFields = () => {
        return type.tandemTypeFields.sort(sortFieldsByTitleness).map(field => {
            return (
                <div className="border-b border-gray-200 flex gap-2">
                    <h4 className="text-lg font-semibold text-black">{startCase(field.field_name)}:</h4>
                    <p className="text-gray-600">{getValue(field, data[field.field_name])}</p>
                </div>
            )
        })
    }

    console.log({type})

    return (
        <div className="p-8 bg-white shadow-md rounded-lg flex flex-col">
            <div className="flex gap-2 justify-between items-center mb-4">
                <h1 className="text-2xl font-bold mb-4 text-black">{startCase(type.tandemType.name)}</h1>
                <Link className={"border border-gray-300 rounded-md px-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900"} to={`/detail/${type.tandemType.id}`}>Type</Link>
                {isWorkspace && <Link className={"border border-gray-300 rounded-md px-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900"} to={`/workspace/${data.id}`}>Go to Workspace</Link> }
            </div>
            {renderFields()}
            <ListPanel tandemTypeDetails={type}/>
        </div>
    );
}

export default DataDetail;