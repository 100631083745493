import React, { useState, useContext } from 'react';
import logo from '../../logo.png';
import '../../App.css';
import LoginModal from '../../components/LoginModal';
import { AuthContext } from '../../components/AuthProvider';

const HomePage: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const { loggedIn, login, logout } = useContext(AuthContext);

    const openModal = () => {
        setShowModal(true);
    };

    return (
        <div className="App">
            <div className="App-header">
                <h1>Tandem Brain</h1>
                <h2>Two brains are better than one.</h2>
                {loggedIn ? (
                    <button className="absolute top-1 right-1 bg-blue-500 text-black rounded-2xl p-2" onClick={logout}>Logout</button>
                ) : (
                    <button className="absolute top-1 right-1 bg-blue-500 text-black rounded-2xl p-2" onClick={openModal}>Login/Signup</button>
                )}
            </div>
            <div className="image-container">
                <img src={logo} className="App-logo" alt="logo"/>
            </div>
            {showModal && (
                <LoginModal open={showModal} handleClose={() => setShowModal(false)} />
            )}
        </div>
    );
};

export default HomePage;


