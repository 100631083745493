import React from 'react';
import {TandemNode, TandemTypeDetails, TandemTypeField} from '../types';
import {
    ROW_ITEM
} from '../constants/styles.constants';
import startCase from 'lodash/startCase';
import { sortFieldsByTitleness } from '../utils';
import {Link} from "react-router-dom";

interface DetailRowProps {
    tandemTypeDetails: TandemTypeDetails;
    data: TandemNode;
    onClick?: () => void;
}



const DetailRow: React.FC<DetailRowProps> = ({ tandemTypeDetails, data, onClick }) => {
    console.log('DetailRow', tandemTypeDetails, data);
    if (!tandemTypeDetails) return null;
    if (!tandemTypeDetails.tandemTypeFields) return null;
    if (!tandemTypeDetails.tandemTypeFields.length) return null;

    const bestNameField: TandemTypeField = tandemTypeDetails.tandemTypeFields.sort(sortFieldsByTitleness)[0];

    const buttons = [];

    if(tandemTypeDetails.tandemType.id === '00000000-0000-0000-0000-000000000000') {
        buttons.push(<Link className="text-white bg-blue-500 hover:bg-blue-700 font-bold m-1 px-4 rounded" to={`/detail/${data.id}`}>Type</Link>);
    }

    return (
        <div className={ROW_ITEM} >
            <h4 onClick={onClick}>{startCase(data[bestNameField.field_name])}</h4>
            {buttons}
        </div>
    );
};

export default DetailRow;

