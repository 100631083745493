import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { apiLogin } from '../api';
import { User } from '../types';

interface AuthContextProps {
    loggedIn: boolean;
    user: User | null;
    token: string | null;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

export const AuthContext = createContext<Partial<AuthContextProps>>({});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            setLoggedIn(true);
            setToken(token);
        }
        const user = localStorage.getItem('user');
        if (user) {
            setUser(JSON.parse(user));
        }
    }, []);

    const login = async (email: string, password: string) => {
        try {
            const response = await apiLogin(email, password);
            if (response && response.token) {
                localStorage.setItem('jwtToken', response.token);
                setToken(response.token); // Update token state
                setLoggedIn(true); // Update loggedIn state
            }
            if (response && response.user) {
                localStorage.setItem('user', JSON.stringify(response.user));
                setUser(response.user);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const logout = async () => {
        // Remove the token from local storage and update state
        await localStorage.removeItem('jwtToken');
        await localStorage.removeItem('user');
        setLoggedIn(false);
        setToken(null);
    };

    return (
        <AuthContext.Provider value={{ loggedIn, token, login, logout, user }}>
            {children}
        </AuthContext.Provider>
    );
};