import React, {useEffect, useState} from 'react';
import {
    addNewNode,
    getNodeById,
    getTandemTypeDetails,
    searchNodes,
    SearchRequestDataQueryType, updateNode,
} from "../../api";
import {TandemTypeDetails} from "../../types";
import {useParams} from "react-router-dom";
import {
    PYTHON_FUNCTION_TYPE_ID,
    WORKSPACE_FUNCTION_MAP_TYPE_ID,
    WORKSPACE_OBJECT_TYPE_ID,
    WORKSPACE_TYPE_ID
} from "../../typeIdConstants";
import UseViewport from "./useViewport";
import {WorkspaceObject, WorkspaceObjectDef} from "./workspaceTypes";
import {Visualizer} from "./Visualizer";
import AddItemModal from "../../components/AddItemModal";
import WorkspaceDetail from "../../components/WorkspaceDetail";

// todo use this for security
// https://web.dev/articles/sandboxed-iframes#safely-sandboxing-eval

// /workspace/:workspaceId

//
// CRON_TYPE_ID = '6c69e474-c790-452d-bf8c-3a0e246f21e2'
// RUNS_TYPE_ID = '06edc402-97fb-4341-a614-d1d85269685f'
//
// WORKSPACE_TYPE_ID = '072f0cf4-7f45-43e0-b961-a7f1b64a60c7'
// WORKSPACE_OBJECT_TYPE_ID = 'da237cd2-8e80-4fb1-80f8-b254278bf288'
// WORKSPACE_FUNCTION_MAP_TYPE_ID = 'ac89bff3-e3d7-4c6a-98bf-8ed591a2a034'
// PYTHON_FUNCTION_TYPE_ID = '02ce0409-5510-4fa8-9124-69358c2c4e52'


const Workspace: React.FC = () => {
    const {workspaceId} = useParams<{ workspaceId: string }>();

    const [workspaceTandemTypeDetails, setWorkspaceTandemTypeDetails] = useState<TandemTypeDetails | null>(null);
    const [workspace, setWorkspace] = useState<Record<string, any> | null | undefined>(null);
    const [workspaceObjectDefs, setWorkspaceObjectDefs] = useState<WorkspaceObjectDef[] | null | undefined>(null);

    const [isAddNewNodeModalOpen, setIsAddNewNodeModalOpen] = useState(false);
    const [insertExistingModalOpen, setInsertExistingModalOpen] = useState(false);

    const [addItemTandemTypeDetails, setAddItemTandemTypeDetails] = useState<TandemTypeDetails | null>(null);
    const [addConnectionWorkspaceObject, setAddConnectionWorkspaceObject] = useState<WorkspaceObjectDef | null>(null);
    const [workspaceFunctionTandemTypeDetails, setWorkspaceFunctionTandemTypeDetails] = useState<TandemTypeDetails | null>(null);

    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    const {
        view,
        onHandleMouseDown,
        onHandleMouseWheel,
        onHandleMouseUp,
        onHandleMouseMove,
        onHandleTouchMove,
    } = UseViewport({workspaceObjectDefs, setWorkspaceObjectDefs, canvasRef});

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
        }
    }, [canvasRef.current]);


    useEffect(() => {
        getTandemTypeDetails(PYTHON_FUNCTION_TYPE_ID).then((response) => {
            setAddItemTandemTypeDetails(response);
        });
        getTandemTypeDetails(WORKSPACE_FUNCTION_MAP_TYPE_ID).then((response) => {
            setWorkspaceFunctionTandemTypeDetails(response);
        });
    }, []);


    const onAddConnection = (workspaceObjectDef: WorkspaceObjectDef) => {
        setAddConnectionWorkspaceObject(workspaceObjectDef)
    };

    const draw = () => {
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) {
            throw new Error('Could not get 2d context');
        }

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        workspaceObjectDefs?.forEach((workspaceObjectDef: WorkspaceObjectDef) => {
            if (workspaceObjectDef.workspaceObject.x &&
                workspaceObjectDef.workspaceObject.y &&
                workspaceObjectDef.workspaceObject.width &&
                workspaceObjectDef.workspaceObject.height) {
                workspaceObjectDef.visualizer.drawWorkspaceObject({ctx, view, workspaceObjectDef});
            }
        })
    }


    useEffect(() => {
        draw();
    }, [setWorkspaceObjectDefs, view, draw]);

    useEffect(() => {
        const promises = workspaceObjectDefs?.map(async (workspaceObjectDef: WorkspaceObjectDef) => {
            return updateNode(WORKSPACE_OBJECT_TYPE_ID, workspaceObjectDef.workspaceObjectId, {
                x: workspaceObjectDef.workspaceObject.x,
                y: workspaceObjectDef.workspaceObject.y,
                width: workspaceObjectDef.workspaceObject.width,
                height: workspaceObjectDef.workspaceObject.height,
            })
        })
    }, [workspaceObjectDefs]);

    const stepNodes = () => {
        // for (const node of nodes) {
        //     node.step();
        // }
    }

    const onStep = () => {
        stepNodes();
        draw();
    }

    useEffect(() => {
        draw();
    }, [canvasRef.current]);


    const onHandleResize = (e: UIEvent) => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
        }
        draw();
    }

    const handleCloseAddNewModal = (node?: Record<string, any>, tandemTypeDetails?: TandemTypeDetails) => {
        setIsAddNewNodeModalOpen(false);
        if (node && tandemTypeDetails) {
            addNewNode(WORKSPACE_OBJECT_TYPE_ID, {
                workspace_id: workspaceId,
                node_id: node.id,
                tandem_type_id: tandemTypeDetails.tandemType.id,
                x: view.x + (view.scale * window.innerWidth / 2),
                y: view.y + (view.scale * window.innerHeight / 2),
                width: view.scale * window.innerWidth / 4,
                height: view.scale * window.innerHeight / 4,
            }).then((response) => {
                console.log('response', response);
                const workspaceObject = response as unknown as WorkspaceObject;

                workspaceObjectDefs?.push({
                    type: tandemTypeDetails,
                    node: node,
                    workspaceObject,
                    workspaceObjectId: workspaceObject.id,
                    visualizer: new Visualizer(),
                    selected: true,
                    connections: [],
                })
            });
        }
    }

    const handleCloseAddConnectionModal = (node?: Record<string, any>, tandemTypeDetails?: TandemTypeDetails) => {
        setAddConnectionWorkspaceObject(null);
        if (node && tandemTypeDetails) {
            addNewNode(WORKSPACE_OBJECT_TYPE_ID, {
                workspace_id: workspaceId,
                node_id: node.id,
                tandem_type_id: tandemTypeDetails.tandemType.id,
                x: view.x + (view.scale * window.innerWidth / 2),
                y: view.y + (view.scale * window.innerHeight / 2),
                width: view.scale * window.innerWidth / 4,
                height: view.scale * window.innerHeight / 4,
            }).then((response) => {
                console.log('response', response);
                const workspaceObject = response as unknown as WorkspaceObject;

                workspaceObjectDefs?.push({
                    type: tandemTypeDetails,
                    node: node,
                    workspaceObject,
                    workspaceObjectId: workspaceObject.id,
                    visualizer: new Visualizer(),
                    selected: true,
                    connections: [],
                })
            });
        }
    }

    useEffect(() => {
        if (!workspaceId) return;
        getTandemTypeDetails(WORKSPACE_TYPE_ID).then((response) => {
            console.log('response', response)
            setWorkspaceTandemTypeDetails(response);
        });
        getNodeById(WORKSPACE_TYPE_ID, workspaceId).then(async (response) => {
            setWorkspace(response);

            const workspaceObjects = await searchNodes(WORKSPACE_OBJECT_TYPE_ID, {
                where: [{
                    key: 'workspace_id',
                    queryType: SearchRequestDataQueryType.equals,
                    value: workspaceId
                }],
                offset: 0,
                limit: 100,
            })
            // const windowWidth = window.innerWidth;
            // const windowHeight = window.innerHeight;
            const nodes: WorkspaceObjectDef[] = await Promise.all(workspaceObjects.map(async (workspaceObject: any) => {
                return {
                    type: await getTandemTypeDetails(workspaceObject.tandem_type_id),
                    node: await getNodeById(workspaceObject.tandem_type_id, workspaceObject.node_id),
                    workspaceObject: workspaceObject,
                    workspaceObjectId: workspaceObject.id,
                    visualizer: new Visualizer(),
                    selected: false,
                    connections: [],
                }
            }))
            console.log({nodes})

            for (const workspaceObjectDef of nodes) {
                if (workspaceObjectDef.type?.tandemType.id === WORKSPACE_FUNCTION_MAP_TYPE_ID) {
                    const workspaceObjectId = workspaceObjectDef.node.workspace_object_id;
                    const match = nodes.filter((n) => n.workspaceObject.id === workspaceObjectId)
                    console.log({
                        workspaceObjectId,
                        match
                    });
                    match.forEach((n) => {
                        n.connections.push(workspaceObjectDef);                    });
                }
            }

            setWorkspaceObjectDefs(nodes);
        });
    }, [workspaceId]);

    // onResize
    useEffect(() => {
        window.addEventListener('resize', onHandleResize);
        window.addEventListener('mousemove', onHandleMouseMove);
        window.addEventListener('touchmove', onHandleTouchMove);
        return () => {
            window.removeEventListener('resize', onHandleResize);
            window.removeEventListener('mousemove', onHandleMouseMove);
            window.removeEventListener('touchmove', onHandleTouchMove);
        }
    }, []);


    const onHandleKeyDown = (e: React.KeyboardEvent) => {
        console.log('onHandleKeyDown', e.key);
    }

    const onHandleKeyUp = (e: React.KeyboardEvent) => {
        console.log('onHandleKeyUp', e.key);
    }

    const selectWorkspaceObject = (workspaceObjectDef: WorkspaceObjectDef) => {
        if (!workspaceObjectDefs) {
            return
        }
        for (const node of workspaceObjectDefs) {
            node.selected = false;
        }
        workspaceObjectDef.selected = true;
        setWorkspaceObjectDefs(workspaceObjectDefs.map(obj => obj));
    }

    return (
        <div className="flex gap-2"
             onKeyDown={onHandleKeyDown}
             onKeyUp={onHandleKeyUp}>
            {addItemTandemTypeDetails &&
                <AddItemModal open={isAddNewNodeModalOpen} handleClose={handleCloseAddNewModal}
                              tandemTypeDetails={addItemTandemTypeDetails}/>
            }
            {/*{addItemTandemTypeDetails &&*/}
            {/*    <AddCodeModal open={isAddNewNodeModalOpen} handleClose={handleCloseAddNewModal}*/}
            {/*                  tandemTypeDetails={addItemTandemTypeDetails}/>*/}
            {/*}*/}
            {workspaceFunctionTandemTypeDetails && addConnectionWorkspaceObject &&
                <AddItemModal open={true} handleClose={handleCloseAddConnectionModal}
                              defaultValues={{workspace_id: addConnectionWorkspaceObject.node.workspace_id}}
                              tandemTypeDetails={workspaceFunctionTandemTypeDetails}/>}
            {insertExistingModalOpen && <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50">
                <div className="bg-white p-8 w-1/2 m-auto rounded-lg">
                    <h1 className="text-2xl font-bold mb-4">Add Node</h1>
                    <form>
                        <div className="flex flex-col gap-4">
                            <label>
                                <span>Node Type:</span>
                                <input type="text" className="border border-gray-300 rounded-md p-2"/>
                            </label>
                            <label>
                                <span>Node Name:</span>
                                <input type="text" className="border border-gray-300 rounded-md p-2"/>
                            </label>
                            <button
                                className="border border-gray-300 rounded-md px-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900">Add
                                Node
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            }
            <div className="flex flex-col gap-2 w-1/6 bg-quaternary h-screen">
                <h1 className="font-bold text-2xl">{workspace?.name} Workspace:</h1>
                <button
                    className={"border border-gray-300 rounded-md px-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900"}
                    onClick={() => setIsAddNewNodeModalOpen(true)}>Add Python Function Node
                </button>
                <button
                    className={"border border-gray-300 rounded-md px-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900"}
                    onClick={() => setInsertExistingModalOpen(true)}>import existing Node
                </button>

                {workspaceObjectDefs?.map((workspaceObjectDef: WorkspaceObjectDef) => {
                    return <WorkspaceDetail key={workspaceObjectDef.workspaceObjectId}
                                            workspaceObjectDef={workspaceObjectDef} onClick={selectWorkspaceObject}
                                            onAddConnection={onAddConnection}/>
                })}
            </div>
            <canvas id="canvas" className="w-5/6 bg-primary h-screen" ref={canvasRef}
                    onMouseDown={onHandleMouseDown}
                    onMouseUp={onHandleMouseUp}
                // onMouseLeave={onHandleMouseLeave}
                    onWheel={onHandleMouseWheel}
                // onContextMenu={onHandleContextMenu}
            >
            </canvas>
        </div>
    );
};

export default Workspace;

