export enum FieldType {
    INTEGER = "integer",
    FLOAT = "float",
    DATETIME = "datetime",
    STRING = "string",
    BOOLEAN = "boolean",
    DATE = "date",
    TIME = "time",
    UUID = "uuid",
}


export interface TandemNode {
    id: string;
    created_at: string;
    updated_at: string;
    [key: string]: any;
}


export interface TandemType {
    id: string;
    name: string;
    user_id: string;
    created_at: string;
    updated_at: string;
}

export interface TandemTypeField {
    id: string;
    tandem_type_id: string;
    field_name: string;
    field_type: FieldType;
    foreign_key_field: string | undefined;
    is_unique: boolean;
    importance?: number;
    created_at: string;
    updated_at: string;
}

export interface TandemTypeDetails {
    tandemType: TandemType;
    tandemTypeFields: TandemTypeField[];
}

export interface User {
    id: string;
    name: string;
    email: string;
    created_at: string;
}

export interface ChatCompletionRequest {
    model: string;
    messages: ChatCompletionMessage[];
    max_tokens?: number;
    temperature?: number;
    top_p?: number;
    n?: number;
    stream?: boolean;
    stop?: string[];
    presence_penalty?: number;
    response_format?: ChatCompletionResponseFormat;
    seed?: number;
    frequency_penalty?: number;
    logit_bias?: Record<string, number>;
    logprobs?: boolean;
    top_logprobs?: number;
    user?: string;
    functions?: FunctionDefinition[];
    function_call?: any;
    tools?: Tool[];
    tool_choice?: any;
}


export type ChatMessagePartType = "text" | "image_url";

export interface ChatMessagePart {
    type: ChatMessagePartType;
    text?: string;
    image_url?: ChatMessageImageURL;
}

export interface ChatCompletionMessage {
    role: string;
    content: string;
    multi_content: ChatMessagePart[];
    name?: string;
    function_call?: FunctionCall;
    tool_calls?: ToolCall[];
    tool_call_id?: string;
}


export type ImageURLDetail = "high" | "low" | "auto";

export interface ChatMessageImageURL {
    url: string;
    detail: ImageURLDetail;
}


export type ChatCompletionResponseFormatType = "json_object" | "text";

export interface ChatCompletionResponseFormat {
    type: ChatCompletionResponseFormatType;
}

export interface FunctionDefinition {
    name: string;
    description?: string;
    parameters: any;
}

export interface FunctionCall {
    name: string;
    parameters: any;
}

export interface Tool {
    name: string;
    description: string;
    parameters: any;
}

export interface ToolCall {
    name: string;
    parameters: any;
}

export type Task = {
    id: string;
    title: string;
    details: string;
    status: string;
    documentJson: any[];
    parentId: string;
    nextRun: Date;
    archived: boolean;
    showOverOtherApps: boolean;
    isEnabled: boolean;
}