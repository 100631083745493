import React, {useEffect} from "react";
import {WorkspaceObjectDef} from "./workspaceTypes";

export type View = {
    x: number,
    y: number,
    scale: number,
}


export const translateToView = ({view, x, y, width = 0, height = 0}: {
    view: View,
    x: number,
    y: number,
    width: number,
    height: number
}) => {
    return {
        x: (x - view.x) * view.scale,
        y: (y - view.y) * view.scale,
        width: width * view.scale,
        height: height * view.scale,
    }
}


const UseViewport = ({workspaceObjectDefs, setWorkspaceObjectDefs, canvasRef}: {
    workspaceObjectDefs: WorkspaceObjectDef[] | null | undefined,
    setWorkspaceObjectDefs: React.Dispatch<React.SetStateAction<WorkspaceObjectDef[] | null | undefined>>,
    canvasRef: React.RefObject<HTMLCanvasElement>
}) => {
    const [view, setView] = React.useState<View>({
        x: 0,
        y: 0,
        scale: 1
    });

    const [isDragging, setIsDragging] = React.useState(false);

    // Pixels from the top left of the canvas
    const [mousePosition, setMousePosition] = React.useState<{ x: number, y: number } | undefined>(undefined);
    const [newMousePosition, setNewMousePosition] = React.useState<{ x: number, y: number } | undefined>(undefined);
    const [wheelDelta, setWheelDelta] = React.useState<number | undefined>(undefined);


    useEffect(() => {
        if (!mousePosition || !wheelDelta) return;
        // console.log('wheelDelta', wheelDelta)
        const newScale = view.scale * (1.000 + wheelDelta / 1000);

        const mousePositionWorkspaceUnitsX = mousePosition.x / view.scale;
        const mousePositionWorkspaceUnitsY = mousePosition.y / view.scale;

        const newMousePositionPixelsX = mousePositionWorkspaceUnitsX * newScale;
        const newMousePositionPixelsY = mousePositionWorkspaceUnitsY * newScale;

        const newViewX = view.x + (newMousePositionPixelsX - mousePosition.x) / newScale;
        const newViewY = view.y + (newMousePositionPixelsY - mousePosition.y) / newScale;

        setView({
            x: newViewX,
            y: newViewY,
            scale: newScale
        });
        setWheelDelta(undefined);
    }, [wheelDelta]);

    // Global listeners don't have access to up to date react state
    // we need to set the state and not use any local state
    const onHandleMouseWheel = (e: React.WheelEvent) => {
        setWheelDelta((prev: number | undefined) => {
            if (prev === undefined) {
                return e.deltaY;
            }
            return Math.abs(prev + e.deltaY);
        });
    }

    const onHandleMouseDown = (e: React.MouseEvent) => {
        console.log('onHandleMouseDown', isDragging);
        if (workspaceObjectDefs) {
            for (let j = 0; j < workspaceObjectDefs.length; j++) {
                workspaceObjectDefs[j].selected = false;
            }

            for (let i = 0; i < workspaceObjectDefs.length; i++) {
                const obj = workspaceObjectDefs[i];

                const {
                    x,
                    y,
                    width,
                    height
                } = translateToView({
                    view: view,
                    x: obj.workspaceObject.x,
                    y: obj.workspaceObject.y,
                    width: obj.workspaceObject.width,
                    height: obj.workspaceObject.height
                });

                const canvas = canvasRef.current;
                if(!canvas) return;

                const mouseX = e.clientX - canvas.getBoundingClientRect().left;
                const mouseY = e.clientY - canvas.getBoundingClientRect().top;

                for (const [cornerX, cornerY] of [
                    [0, 0],
                    [1, 0],
                    [0, 1],
                    [1, 1]
                ]) {
                    const cornerXPixels = x + cornerX * width;
                    const cornerYPixels = y + cornerY * height;
                    if (Math.abs(mouseX - cornerXPixels) < 10 && Math.abs(mouseY - cornerYPixels) < 10) {
                        obj.selected = true;
                        obj.selectedCornerX = cornerX;
                        obj.selectedCornerY = cornerY;
                        break;
                    }
                }

                if (mouseX > x && mouseX < x + width &&
                    mouseY > y && mouseY < y + height) {
                    obj.selected = true;
                    obj.selectedCornerX = undefined;
                    obj.selectedCornerY = undefined;
                    break;
                }
            }
        }

        setWorkspaceObjectDefs(workspaceObjectDefs?.map(obj => obj));
        setIsDragging(true);
    }

    const onHandleMouseUp = (e: React.MouseEvent) => {
        console.log('onHandleMouseUp', isDragging);
        setIsDragging(false);
    }

    // Global listeners don't have access to up to date react state
    // we need to set the state and not use any local state
    const onHandleMouseMove = (e: MouseEvent) => {
        let globalMousePos;
        globalMousePos = {
            x: e.clientX,
            y: e.clientY,
        };

        const canvas = canvasRef.current;

        if (!canvas) {
            setMousePosition(undefined);
            setNewMousePosition(undefined);
            console.log('no canvas');
            return;
        }

        let boundingRect = canvas.getBoundingClientRect();
        if (globalMousePos) {
            const canvasMousePos = {
                x: globalMousePos.x - boundingRect.left,
                y: globalMousePos.y - boundingRect.top,
            };
            setNewMousePosition(canvasMousePos);
        } else {
            setMousePosition(undefined);
            setNewMousePosition(undefined);
        }
    };

    const onHandleTouchMove = (e: TouchEvent) => {
        let globalMousePos;

        const touches = e.changedTouches;
        const touch = touches[0];
        globalMousePos = {
            x: touch.clientX,
            y: touch.clientY,
        };

        const canvas = canvasRef.current;

        if (!canvas) {
            setMousePosition(undefined);
            setNewMousePosition(undefined);
            return;
        }

        let boundingRect = canvas.getBoundingClientRect();
        if (globalMousePos) {
            const canvasMousePos = {
                x: globalMousePos.x - boundingRect.left,
                y: globalMousePos.y - boundingRect.top,
            };
            setNewMousePosition(canvasMousePos);
        } else {
            setMousePosition(undefined);
            setNewMousePosition(undefined);
        }

    };

    useEffect(() => {
        const selectedObject = workspaceObjectDefs?.find(obj => obj.selected);

        if (isDragging && newMousePosition && mousePosition) {
            const dX = (newMousePosition.x - mousePosition.x) / view.scale;
            const dY = (newMousePosition.y - mousePosition.y) / view.scale;
            const newX = view.x - dX;
            const newY = view.y - dY;

            if (selectedObject) {
                if (selectedObject.selectedCornerX == 1 && selectedObject.selectedCornerY == 1) {
                    selectedObject.workspaceObject.width += dX;
                    selectedObject.workspaceObject.height += dY;
                } else if(selectedObject.selectedCornerX == 0 && selectedObject.selectedCornerY == 1) {
                    selectedObject.workspaceObject.x += dX;
                    selectedObject.workspaceObject.width -= dX;
                    selectedObject.workspaceObject.height += dY;
                } else if (selectedObject.selectedCornerX == 1 && selectedObject.selectedCornerY == 0) {
                    selectedObject.workspaceObject.y += dY;
                    selectedObject.workspaceObject.width += dX;
                    selectedObject.workspaceObject.height -= dY;
                } else if (selectedObject.selectedCornerX == 0 && selectedObject.selectedCornerY == 0) {
                    selectedObject.workspaceObject.x += dX;
                    selectedObject.workspaceObject.y += dY;
                    selectedObject.workspaceObject.width -= dX;
                    selectedObject.workspaceObject.height -= dY;
                } else {
                    selectedObject.workspaceObject.x += dX;
                    selectedObject.workspaceObject.y += dY;
                }
            } else {
                setView({
                    x: newX,
                    y: newY,
                    scale: view.scale
                });
            }
        }

        if (newMousePosition) {
            // console.log('setMousePosition', newMousePosition);
            setMousePosition(newMousePosition);
            setNewMousePosition(undefined);
        }

    }, [newMousePosition, mousePosition, isDragging, view]);

    return {
        view,
        onHandleMouseWheel,
        onHandleMouseDown,
        onHandleMouseUp,
        onHandleMouseMove,
        onHandleTouchMove,
    }
}

export default UseViewport;
