import React, {useEffect, useState} from 'react';
import '../../App.css';
import {useParams} from "react-router-dom";
import {getNodeById, getTandemTypeDetails} from "../../api";
import { TandemTypeDetails} from "../../types";
import DataDetail from "../../components/DetailDetail";

// Usage:
// <Link className="text-white" to={`/detail/${typeId}/${id}`}>Types</Link>
const DetailPage: React.FC = () => {
    const { typeId, id } = useParams<{ id: string, typeId: string }>();
    const [tandemType, setTandemType] = useState<TandemTypeDetails | null>(null);
    const [data, setData] = useState<Record<string, any> | null>(null);

    useEffect(() => {
        if(!typeId) return;
        if(!id) return;
        getTandemTypeDetails(typeId).then((response) => {
            console.log('response', response)
            setTandemType(response);
        });
        getNodeById(typeId, id).then((response) => {
            setData(response);
        });
    }, [typeId, id]);

    console.log({tandemType: tandemType, data: data})

    if(!tandemType) return <div>Loading Type...</div>;
    if(!data) return <div>Loading Data...</div>;

    return <DataDetail data={data} type={tandemType} />;
};

export default DetailPage;


