import React, { useState } from 'react';
import { FieldType, TandemNode, TandemTypeDetails } from '../types';
import { addNewNode } from '../api';
import startCase from 'lodash/startCase';

interface AddItemModalProps {
    open: boolean;
    handleClose: (node?: TandemNode, tandemTypeDetails?: TandemTypeDetails) => void;
    tandemTypeDetails: TandemTypeDetails;
    defaultValues?: Record<string, string>;
}

const AddItemModal: React.FC<AddItemModalProps> = ({ open, handleClose, tandemTypeDetails, defaultValues }) => {
    const [formValues, setFormValues] = useState<Record<string, string>>(defaultValues || {});
    const fieldsToShow = tandemTypeDetails.tandemTypeFields.filter(
        (field) => field.field_name !== 'id' && field.field_name !== 'created_at' && field.field_name !== 'updated_at' && field.field_name !== 'user_id'
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const packageFormValues = () => {
        // cast from string into fieldsToShow.typescriptTypeName
        const packaged = fieldsToShow.map((field) => {
            const stringValue = formValues[field.field_name];
            switch (field.field_type) {
                case 'string':
                    return [field.field_name, stringValue];
                case FieldType.INTEGER:
                    return [field.field_name, Number(stringValue)];
                case FieldType.BOOLEAN:
                    return [field.field_name, stringValue === 'true'];
                case FieldType.DATETIME:
                    return [field.field_name, new Date(stringValue)];
                default:
                    return [field.field_name, stringValue];
            }
        });
        return Object.fromEntries(packaged);
    };

    const handleSubmit = async () => {
        const newNode = await addNewNode(tandemTypeDetails.tandemType.id, packageFormValues());
        handleClose(newNode, tandemTypeDetails);
    };

    if (!open) {
        return null
    }

    return (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
                    <div className="flex justify-between items-center border-b pb-3">
                        <h2 className="text-xl font-semibold">Add New {startCase(tandemTypeDetails.tandemType.name)}</h2>
                        <button onClick={() => handleClose()} className="text-gray-500 hover:text-gray-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-4">
                        {fieldsToShow.map((field) => (
                            <div key={field.id} className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={field.field_name}>
                                    {startCase(field.field_name)}
                                </label>
                                <input
                                    id={field.field_name}
                                    name={field.field_name}
                                    type="text"
                                    value={formValues[field.field_name] || ''}
                                    onChange={handleInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                        <button
                            onClick={() => handleClose()}
                            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
    );
};

export default AddItemModal;
