import React, {useState, useContext, useEffect} from 'react';
import '../../App.css';
import {getNodeById, searchNodes, SearchRequestDataQueryType, SortDirection, updateNode} from "../../api";
import IconDroplet from "../../svgs/IconDroplet";
import {Task} from "../../types";
import {taskTypeId} from "../../constants";
import {useNavigate, useParams} from "react-router-dom";
import TasksListView from "./TasksListView";

type TabsIdType = "details" | "output"

const TaskEditPage: React.FC = () => {
    // get task id from url /tasks/:id
    const { taskId } = useParams<{ taskId: string }>();
    const [task, setTask] = useState<Task|undefined>();
    const [title, setTitle] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [showOverOtherApps, setShowOverOtherApps] = useState<boolean>(true);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchItems = async () => {
            console.log('getting one task')
            setTask(undefined);
            if (!taskId) return;

            const response = await getNodeById(taskTypeId, taskId);
            if (response) setTask(response as unknown as Task | undefined);
        };

        fetchItems();
    }, []);

    useEffect(() => {
        if (!task) return;
        setTitle(task.title);
    }, [task]);

    useEffect(() => {
        if (!task) return;
        setDetails(task.details);
    }, [task]);

    const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (taskId == null) {
            return
        }
        const newData = {
            title,
            details,
            show_over_other_apps: showOverOtherApps,
            is_enabled: isEnabled
        }
        await updateNode(taskTypeId, taskId, newData);
        // redirect to task detail page using react-router-dom
        navigate(`/tasks/${taskId}`);
    };

    const toggleShowOverOtherApps = () => {
        setShowOverOtherApps(!showOverOtherApps);
    }

    const toggleIsEnabled = () => {
        setIsEnabled(!isEnabled);
    }

    if (!task) return <div>Loading Task...</div>;

    return (
        <div className='flex flex-col h-full'>
            <a href={`/tasks`} className='flex flex-row'>
                <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                <h1>Tasks</h1>
            </a>
            <div className={'flex flex-col h-1/4'}>
                <div className='flex flex-row p-4'>
                    <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                    <h1 className={'font-bold text-3xl'}>{task.title}</h1>
                </div>
                <div className='flex flex-row p-2'>
                    <button type="button" className='flex flex-row border-2 p-1 rounded-xl'><IconDroplet
                        width={'25px'}
                        height={'25px'}
                        color={'white'}/> Add
                        Input
                    </button>
                    {/*    {inputs.map(input: Input) => (*/}
                    {/*        <div key={input.id}*/}
                    {/*                <p>{input.type}</p>*/}
                    {/*                <p>{input.name}</p>*/}
                    {/*                <p>{input.value}</p>*/}
                    {/*)}*/}
                    <p>See all</p>
                </div>
                <div>
                    <h2>{task.details}</h2>
                </div>

                <div>
                    <form onSubmit={handleEditSubmit}>
                        <input
                            type="text"
                            name="Title"
                            value={title}    // inject state correspond to input and so on
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setTitle(e.target.value)
                            }}
                        />
                        <br/>
                        <input
                            type="text"
                            name="details"
                            value={details}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setDetails(e.target.value)
                            }}
                        />
                        <br/>
                        <button onClick={toggleShowOverOtherApps}>
                            Show Over Other Apps: {showOverOtherApps ? 'Yes' : 'No'}
                        </button>
                        <br/>
                        <button onClick={toggleIsEnabled}>
                            Enabled: {isEnabled ? 'Yes' : 'No'}
                        </button>
                        <br/>
                        <button type="submit">
                            Submit
                        </button>
                    </form>
                </div>
                {/* subtasks*/}
            </div>

        </div>
    );
};

export default TaskEditPage;


