import React, {useState, useContext, useEffect} from 'react';
import '../../App.css';
import {addNewNode, getNodeById, searchNodes, SearchRequestDataQueryType, SortDirection, updateNode} from "../../api";
import IconDroplet from "../../svgs/IconDroplet";
import {Task} from "../../types";
import {taskTypeId} from "../../constants";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TasksListView from "./TasksListView";

type TabsIdType = "details" | "output"

const TaskEditPage: React.FC = () => {
    // get parentId from url /tasks/add?parentId=123
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const parentId = queryParams.get('parentId');


    const [title, setTitle] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [showOverOtherApps, setShowOverOtherApps] = useState<boolean>(true);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);

    const navigate = useNavigate();

    // useEffect(() => {
    //     const fetchItems = async () => {
    //         setTask(undefined);
    //         if (!parentId) return;
    //
    //         const response = await getNodeById(taskTypeId, taskId);
    //         if (response) setTaskData(response as unknown as Task | undefined);
    //     };
    //
    //     fetchItems();
    // }, []);


    //
    // useEffect(() => {
    //     if (!task) return;
    //     setDetails("Details");
    // }, [task]);

    const handleAddSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const newData = {
            title,
            details,
            parent_id: parentId,
            archived: false,
            show_over_other_apps: showOverOtherApps,
            is_enabled: isEnabled
        }
        await addNewNode(taskTypeId, newData);
        if (parentId) {
            navigate(`/tasks/${parentId}`);
        } else {
            navigate(`/tasks/`);
        }
    };

    const toggleShowOverOtherApps = () => {
        setShowOverOtherApps(!showOverOtherApps);
    }

    const toggleIsEnabled = () => {
        setIsEnabled(!isEnabled);
    }

    // if (!task) return <div>Loading Task...</div>;

    return (
        <div className='flex flex-col h-full'>
            <a href={`/tasks`} className='flex flex-row'>
                <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                <h1>Tasks</h1>
            </a>
            <div className={'flex flex-col h-1/4'}>
                <div>
                    <form onSubmit={handleAddSubmit}>
                        <input
                            type="text"
                            name="Title"
                            value={title}
                            placeholder={"Title"}// inject state correspond to input and so on
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setTitle(e.target.value)
                            }}
                        />
                        <br/>
                        <input
                            type="text"
                            name="details"
                            value={details}
                            placeholder={"Details"}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setDetails(e.target.value)
                            }}
                        />
                        <br/>
                        <button onClick={toggleShowOverOtherApps}>
                            Show Over Other Apps: {showOverOtherApps ? 'Yes' : 'No'}
                        </button>
                        <br/>
                        <button onClick={toggleIsEnabled}>
                            Enabled: {isEnabled ? 'Yes' : 'No'}
                        </button>
                        <br/>
                        <button type="submit">
                            Submit
                        </button>
                    </form>
                </div>
                {/* subtasks*/}
            </div>

        </div>
    );
};

export default TaskEditPage;


