import {TandemTypeField} from "./types";

export const sortFieldsByTitleness = (a: TandemTypeField, b: TandemTypeField) => {
    if (a.field_name === b.field_name) return 0;
    if (a.field_name === 'title') return -1;
    if (b.field_name === 'title') return 1;
    if (a.field_name === 'field_name') return -1;
    if (b.field_name === 'field_name') return 1;
    if (b.field_name === 'name') return 1;
    if (a.field_name === 'name') return -1;
    if (b.field_name === 'name') return 1;
    if (a.field_name === 'first_name') return -1;
    if (b.field_name === 'first_name') return 1;
    if (a.field_name === 'last_name') return -1;
    if (b.field_name === 'last_ame') return 1;
    if (a.field_name === 'email') return -1;
    if (b.field_name === 'email') return 1;
    if (a.field_name === 'url') return -1;
    if (b.field_name === 'url') return 1;
    if (a.field_name === 'created_at') return -1;
    if (b.field_name === 'created_at') return 1;
    return (a.importance ?? 0) - (b.importance ?? 0);
}
