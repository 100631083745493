import React, {useState, useContext, useEffect} from 'react';
import '../../App.css';
import {getNodeById, searchNodes, SearchRequestDataQueryType, SortDirection} from "../../api";
import IconDroplet from "../../svgs/IconDroplet";
import {Task} from "../../types";
import {taskTypeId} from "../../constants";
import {useParams} from "react-router-dom";
import TasksListView from "./TasksListView";

type TabsIdType = "details" | "output"

const TaskDetailPage: React.FC = () => {
    // get task id from url /tasks/:id
    const { taskId } = useParams<{ taskId: string }>();
    const [task, setTask] = useState<Task|undefined>();
    const [tabId, setTabId] = useState<TabsIdType>("details")

    useEffect(() => {
        const fetchItems = async () => {
            console.log('getting one task')
            setTask(undefined);
            if (!taskId) return;

            const response = await getNodeById(taskTypeId, taskId);
            if (response) setTask(response as unknown as Task | undefined);
        };

        fetchItems();
    }, []);

    const toggleAreDetailsVisible = () => {
        setTabId("details")
    }

    const toggleIsOutputVisible = () => {
        setTabId("output")
    }

    if (!task) return <div>Loading Task...</div>;

    return (
        <div className='flex flex-col h-full'>
            <a href={`/tasks`} className='flex flex-row'>
                <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                <h1>Tasks</h1>
            </a>
            <div className='flex flex-row justify-center gap-5'>
                <button type="button" className='flex flex-row p-1 rounded-xl'
                        onClick={toggleAreDetailsVisible}>Details
                </button>
                <button type="button" className='flex flex-row p-1 rounded-xl' onClick={toggleIsOutputVisible}>Output
                </button>
            </div>

            {tabId == "details" && (
                <div className={'flex flex-col h-1/4'}>
                    <div className='flex flex-row p-4'>
                        <IconDroplet width={'25px'} height={'25px'} color={'white'}/>
                        <h1 className={'font-bold text-3xl'}>{task.title}</h1>
                    </div>
                    <div className='flex flex-row p-2'>
                        <button type="button" className='flex flex-row border-2 p-1 rounded-xl'><IconDroplet
                            width={'25px'}
                            height={'25px'}
                            color={'white'}/> Add
                            Input
                        </button>
                        {/*    {inputs.map(input: Input) => (*/}
                        {/*        <div key={input.id}*/}
                        {/*                <p>{input.type}</p>*/}
                        {/*                <p>{input.name}</p>*/}
                        {/*                <p>{input.value}</p>*/}
                        {/*)}*/}
                        <p>See all</p>
                    </div>
                    <div>
                        <h2>{task.details}</h2>
                    </div>
                    <div>
                        <a href={`/tasks/${taskId}/edit`} className='flex flex-row'>
                            <h1>Edit</h1>
                        </a>
                    </div>
                    {/* subtasks*/}
                    <h2>Subtasks</h2>
                    <TasksListView parentId={taskId}/>
                </div>
            )}
            {/* output*/}
            {tabId == "output" && (
                <div className='flex gap-5  flex-row flex-wrap justify-around'>
                    <h1>Output</h1>
                    {task.documentJson}
                </div>
            )}
        </div>
    );
};

export default TaskDetailPage;


