
export const BACKGROUND_COLOR = '#000';

export const PRIMARY_COLOR = '#add8e6';

// Also defined in tailwind.config.ts 
// change both if you want to change the color scheme
export const SECONDARY_COLOR = '#808080'; // Grey for secondary elements
export const TERTIARY_COLOR = '#404040'; // Dark Grey for tertiary elements
export const QUATERNARY_COLOR = '#202020'; // Darker Grey for quaternary elements
export const ACCENT_COLOR = '#87ceeb'; // Light Blue for accent elements
export const HIGHLIGHT_COLOR = '#ffffff'; // White for highlights and important text


export const CONTAINER_COL = `d-flex justify-content-center align-items-center text-primary p-2 bg-quaternary gap-4 px-2 rounded`;
// white text on dark background
export const CONTAINER_ROW = `flex flex-row justify-content-center align-items-center text-primary p-2 bg-quaternary gap-4 px-2 rounded text-white`;
export const HEADER_ROW = `flex flex-row justify-between align-items-center text-primary p-2 gap-4 px-2 rounded text-black`;

export const BUTTON_STYLE = `rounded-full border-none h-15 text-2xl font-bold`;
export const ROW_ITEM = `flex w-full justify-between items-center bg-quaternary gap-4 px-2 rounded hover:bg-tertiary`;
export const HEADER_TEXT = 'font-bold text-3xl self-start'
export const TITLE_PARENT = `flex justify-between items-center text-2xl font-bold bg-quaternary gap-4 px-2`;
export const TITLE = 'font-bold text-lg self-start'
export const VALUE = 'text-sm self-center text-center';
export const SIDEBAR = 'h-full transition-all duration-200 ease-in-out text-primary flex flex-col justify-between items-center py-4';


