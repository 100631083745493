import React from 'react';
import '../../App.css';
import {deleteAccount} from "../../api";


const SettingsPage: React.FC = () => {

    const onDeleteAllData = async () => {
        // send alert to user
        if (!window.confirm("Are you sure you want to delete all data?")) {
            return
        }
        await deleteAccount()
        // redirect to root page
        window.location.href = '/'
    }

    return (
        <div className='flex flex-col h-full'>
            <button type="button" className='flex flex-row p-1 rounded-xl'
                    onClick={onDeleteAllData}>
                Delete Account
            </button>
        </div>
    );
};

export default SettingsPage;


