import {FieldType, TandemTypeDetails, TandemTypeField} from "../../types";
import {WorkspaceObjectDef} from "./workspaceTypes";
import {min} from "lodash";
import startCase from "lodash/startCase";
import {translateToView, View} from "./useViewport";

export function randomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function randomInt(max: number): any {
    return Math.floor(Math.random() * max);
}

export function hashStringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return `#${"00000".substring(0, 6 - c.length)}${c}`;
}

export class Visualizer {
    constructor() {
        console.log('Visualizer');
    }

    public drawText({
                        text,
                        ctx,
                        x,
                        y,
                        width,
                        height
                    }: {
                        text: string,
                        ctx: CanvasRenderingContext2D,
                        x: number,
                        y: number,
                        width: number,
                        height: number
                    }
    ) {
        ctx.font = "30px Arial";
        ctx.fillStyle = "white";
        const shorterText = text.length > 60 ? text.slice(0, 60) + '...' : text;
        const textWidth = ctx.measureText(shorterText).width;
        const textHeight = ctx.measureText(shorterText).actualBoundingBoxAscent;
        const newFontSize = min([width / textWidth * 30, height / textHeight * 30])
        ctx.font = `${newFontSize}px Arial`;
        const newTextHeight = ctx.measureText(shorterText).actualBoundingBoxAscent;
        ctx.fillText(shorterText, x, y + height / 2 + newTextHeight / 2);
    }

    public drawWorkspaceObject({
                                   workspaceObjectDef,
                                   ctx,
                                   view
                               }: {
        workspaceObjectDef: WorkspaceObjectDef,
        ctx: CanvasRenderingContext2D,
        view: View
    }) {

        const {
            x,
            y,
            width,
            height
        } = translateToView({
            view,
            x: workspaceObjectDef.workspaceObject.x,
            y: workspaceObjectDef.workspaceObject.y,
            width: workspaceObjectDef.workspaceObject.width,
            height: workspaceObjectDef.workspaceObject.height
        });

        let outOfBounds = false;
        let centerX = x + (width / 2);
        let centerY = y + (height / 2);
        if (x + width < 0) {
            outOfBounds = true;
            centerX = 0;
            if (y + (height / 2) < 0) {
                centerY = 0;
            }
        }
        if (y + height < 0) {
            outOfBounds = true;
            centerY = 0;
            if (x + (width / 2) < 0) {
                centerX = 0;
            }
        }
        if (x > ctx.canvas.width) {
            outOfBounds = true;
            centerX = ctx.canvas.width;
            if (y + (height / 2) > ctx.canvas.height) {
                centerY = ctx.canvas.height;
            }
        }
        if (y > ctx.canvas.height) {
            outOfBounds = true;
            centerY = ctx.canvas.height;
            if (x + (width / 2) > ctx.canvas.width) {
                centerX = ctx.canvas.width;
            }
        }

        ctx.fillStyle = hashStringToColor(workspaceObjectDef.workspaceObjectId)
        if (outOfBounds) {
            ctx.fillRect(centerX - 15, centerY - 15, 30, 30);
            return;
        }
        ctx.fillRect(x, y, Math.max(width, 1), Math.max(height, 1));
        if (workspaceObjectDef.selected) {
            ctx.fillStyle = hashStringToColor(workspaceObjectDef.workspaceObjectId)
            for (const [cornerX, cornerY] of [
                [0, 0],
                [1, 0],
                [0, 1],
                [1, 1]
            ]) {
                const pixelX = x + (cornerX * width)
                const pixelY = y + (cornerY * height)
                let size = 20;
                if (workspaceObjectDef.selected && workspaceObjectDef.selectedCornerX === cornerX && workspaceObjectDef.selectedCornerY === cornerY) {
                    size = 30;
                }
                ctx.fillRect(pixelX - (size / 2), pixelY - (size / 2), size, size);
            }
        }

        const node = workspaceObjectDef.node;
        const type = workspaceObjectDef.type;
        this.drawNode({
            node,
            tandemType: type,
            ctx,
            x,
            y,
            width,
            height
        })
        if(workspaceObjectDef.connections) {
            for (const connection of workspaceObjectDef.connections) {
                this.drawConnection({
                    start: workspaceObjectDef,
                    end: connection,
                    ctx,
                    view
                })
            }
        }
    }


    public drawNode({
                        node,
                        tandemType,
                        ctx,
                        x,
                        y,
                        width,
                        height
                    }: {
        node: Record<string, any>,
        tandemType: TandemTypeDetails,
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number
    }) {
        let locationY = y;
        const visibleFields = tandemType.tandemTypeFields.filter((field) => ['id', 'created_at', 'updated_at', 'user_id'].indexOf(field.field_name) === -1);
        const locationYStep = height / visibleFields.length;
        for (const field of visibleFields) {
            this.drawField({
                value: node[field.field_name],
                tandemTypeField: field,
                ctx,
                x,
                y: locationY,
                width,
                height: locationYStep
            })
            locationY += locationYStep;
        }
    }

    public drawField({
                         value,
                         tandemTypeField,
                         ctx,
                         x,
                         y,
                         width,
                         height
                     }: {
        value: any,
        tandemTypeField: TandemTypeField,
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number
    }) {
        if (value == null) {
            return;
        }
        if (tandemTypeField.field_type === 'string') {
            this.drawText({
                text: tandemTypeField.field_name + ':', ctx, x, y: y, width: width / 3, height: height
            })
            this.drawText({
                text: value,
                ctx,
                x: x + width / 2.9,
                y: y,
                width: width * 2 / 3,
                height: height
            })
        } else if (tandemTypeField.field_type === FieldType.DATETIME) {
            const date = new Date(value);
            const dateText = date.toDateString();
            this.drawText({
                text: startCase(tandemTypeField.field_name) + ':',
                ctx,
                x,
                y: y,
                width: width / 3,
                height: height
            })
            this.drawText({
                text: dateText,
                ctx,
                x: x + width / 3,
                y: y,
                width: width * 2 / 3,
                height: height
            })
        } else {
            this.drawText({
                text: startCase(tandemTypeField.field_name) + ':',
                ctx,
                x,
                y: y,
                width: width / 3,
                height: height
            })
            this.drawText({
                text: value.toString(),
                ctx,
                x: x + width / 3,
                y: y,
                width: width * 2 / 3,
                height: height
            })
        }
    }

    public drawConnection({
                              start,
                              end,
                              ctx,
        view
                          }: {
        start: WorkspaceObjectDef,
        end: WorkspaceObjectDef,
        ctx: CanvasRenderingContext2D,
        view: View

    }) {
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 4;

        const startXRaw = start.workspaceObject.x + (start.workspaceObject.width / 2);
        const startYRaw = start.workspaceObject.y + (start.workspaceObject.height / 2);

        const {
            x: startX,
            y: startY,
        } = translateToView({
            view,
            x: startXRaw,
            y: startYRaw,
            width: 0,
            height: 0
        });

        const endXRaw = end.workspaceObject.x + (end.workspaceObject.width / 2);
        const endYRaw = end.workspaceObject.y + (end.workspaceObject.height / 2);

        const {
            x: endX,
            y: endY,
        } = translateToView({
            view,
            x: endXRaw,
            y: endYRaw,
            width: 0,
            height: 0
        });

        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
    }
}

